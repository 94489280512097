import { HighlightedRequest } from '@components/highlightedRequest/highlightedRequest';
import { Location } from '@components/location/location';
import { useLiteStorefront } from '@hooks/use-lite-storefront';
import { useAppSelector } from '@redux/hooks';
import { Column, Row } from '@xo-union/tk-component-grid';
import { Img } from '@xo-union/tk-component-picture';
import { H3 } from '@xo-union/tk-ui-typography';
import classNames from 'classnames';
import React, { type FC } from 'react';
import type { Decorated } from 'types/vendor';
import SecondaryCTA from '../../components/secondary_cta/SecondaryCta';
import { useStorefrontFeatureScannability } from '../../hooks/useStorefrontFeatureScannability';
import type { NavItemProps } from '../nav/types';
import ContactInfo from './components/contact_info';
import Social from './components/social';
import Styles from './styles.scss';

interface VendorInfoProps {
	vendor: Decorated;
}

const VendorInfo: FC<VendorInfoProps> = ({ vendor }) => {
	return (
		<div>
			<ContactInfo />
			<Social social={vendor.social} vendor={vendor} isFooter />
		</div>
	);
};

const Contact: FC = () => {
	const vendor = useAppSelector((state) => {
		return state.vendor.vendor;
	});
	const logoUrl = vendor?.logo?.url;

	const isLiteStorefront = useLiteStorefront();
	const shouldShowScannability = useStorefrontFeatureScannability();

	const hasNoAddress =
		!vendor?.address?.address &&
		!vendor?.address?.city &&
		!vendor?.address?.state;
	const hasNoServiceArea = !vendor?.serviceArea;

	if (!vendor || (isLiteStorefront && hasNoAddress && hasNoServiceArea)) {
		return null;
	}

	return (
		<div
			className={classNames(Styles.contactInfoSection, {
				[Styles.noDivider]: shouldShowScannability,
			})}
			id="contact"
		>
			<Row hGutters={false}>
				<Column sm="12" md="8" orderMd="1">
					<H3 className={Styles.title}>Contact</H3>
					<VendorInfo vendor={vendor} />
				</Column>
				{logoUrl && (
					<Column md="4" orderMd="2" className={Styles.logo}>
						<Img
							src={`${logoUrl}~rs_216.104.fit?quality=90`}
							alt="Vendor Logo"
						/>
					</Column>
				)}
			</Row>
			{!isLiteStorefront && vendor.claimedStatus === 'CLAIMED' && (
				<Row>
					<Column
						sm="12"
						md="12"
						orderMd="1"
						className={Styles.messageVendorCta}
					>
						{!shouldShowScannability && (
							<SecondaryCTA
								sourceContent="Contact CTA"
								icon="send"
								headerText="Interested?"
								linkText="Ask about availability"
								placeholderRfqText="Ask about availability"
							/>
						)}
						{shouldShowScannability && (
							<HighlightedRequest
								messageText="Curious to learn more?"
								buttonText="Start a conversation"
								initiator="Contact CTA"
							/>
						)}
					</Column>
				</Row>
			)}
		</div>
	);
};

export const ContactLocation: FC<NavItemProps> = () => {
	const shouldShowScannability = useStorefrontFeatureScannability();

	return (
		<div className={Styles.contactLocationWrapper}>
			{shouldShowScannability && <Location className={Styles.noDivider} />}
			<Contact />
		</div>
	);
};
