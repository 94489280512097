import { HighlightedRequest } from '@components/highlightedRequest/highlightedRequest';
import Filmstrip from '@components/reviews/components/filmstrip/Filmstrip';
import { useLiteStorefront } from '@hooks/use-lite-storefront';
import { useAppSelector } from '@redux/hooks';
import { selectIsMobile } from '@redux/viewport/selectors';
import type { Review as ReviewType } from '@typings/reviews';
import { StatusBadge } from '@xo-union/tk-component-badges';
import { Body2 } from '@xo-union/tk-ui-typography';
import classNames from 'classnames';
import React, { type FC, memo, useMemo } from 'react';
import type { FCWithChildren } from 'types/react-extended';
import type { Size } from 'types/union/common';
import type VendorAnalytics from '../../../../../../decorators/vendorAnalytics';
import { useStorefrontFeatureScannability } from '../../../../hooks/useStorefrontFeatureScannability';
import ReviewRating from '../../../vendor_info/components/review_rating';
import ReviewStars from '../../../vendor_info/components/review_stars';
import ReviewAvatar from '../review_avatar';
import ReviewSecondaryCTA from '../review_secondary_cta';
import VendorReply from '../vendor_reply';
import Styles from './Review.scss';
import { ReviewBody } from './ReviewBody';
import { useAbbrName } from './hooks/useAbbrName';
import { useReviewDate } from './hooks/useReviewDate';

export interface Props
	extends Omit<ReviewType, 'active' | 'id' | 'profileGuid' | 'reviewer'> {
	hidden?: boolean;
	reviewer: {
		firstName: string;
		lastName: string;
	};
	showSecondaryCTA?: boolean;
	vendorAnalytics?: VendorAnalytics;
	border: boolean;
}

export interface HeaderProps {
	abbrName: string;
	formattedCreatedDate: string;
	rating: number;
	starSize: Size;
}

export interface HeaderScannabilityProps extends HeaderProps {
	isMobile: boolean;
	highlightedReview: JSX.Element | null;
	enableSecondaryCTA?: boolean;
}

export interface HighlightedReviewProps {
	isTestimonial: boolean;
	isLiteStorefront: boolean;
}

const HiddenWrapper: FCWithChildren<{ hidden: boolean }> = ({
	children,
	hidden,
}) => {
	if (!hidden) {
		return <>{children}</>;
	}

	return (
		<div className={Styles.hiddenReview} data-testid="hidden-review">
			{children}
		</div>
	);
};

const Header: FC<HeaderProps> = ({
	abbrName,
	formattedCreatedDate,
	rating,
	starSize,
}) => {
	return (
		<div className={Styles.headingWrapper}>
			<div className={Styles.headingContainer}>
				<ReviewStars
					overallRating={rating}
					size={starSize}
					wrapperClass={Styles.starsWrapper}
				/>
				<span className={Styles.dateAuthor} data-testid="review-created-author">
					{formattedCreatedDate} <span>•</span>
					<span
						itemScope
						itemProp="author"
						itemType="https://schema.org/Person"
					>
						<span itemProp="name"> {abbrName}</span>
					</span>
				</span>
			</div>
		</div>
	);
};

const HeaderScannability: FC<HeaderScannabilityProps> = ({
	abbrName,
	formattedCreatedDate,
	rating,
	starSize,
	isMobile,
	highlightedReview,
	enableSecondaryCTA,
}) => {
	return (
		<div
			className={classNames(Styles.headingContainerColumn, {
				[Styles.headingContainerColumnCta]: enableSecondaryCTA,
			})}
		>
			<div className={Styles.headingLogo}>
				<ReviewAvatar initial={abbrName.charAt(0).toUpperCase()} />
			</div>
			<div className={Styles.headingInfo}>
				<div className={Styles.headingTitle}>
					<span
						itemScope
						itemProp="author"
						itemType="https://schema.org/Person"
					>
						<Body2 bold itemProp="name">
							{abbrName}
						</Body2>
					</span>
					{!isMobile && highlightedReview}
				</div>
				<div className={Styles.headingStars}>
					<ReviewStars
						overallRating={rating}
						size={starSize}
						wrapperClass={Styles.starsWrapper}
					/>
					{rating > 0 && (
						<div className={Styles.headingRating}>
							<ReviewRating overallRating={rating} />
						</div>
					)}
				</div>
			</div>
			<div className={Styles.headingDate}>
				<span className={Styles.dateAuthor} data-testid="review-created-author">
					{formattedCreatedDate}
				</span>
			</div>
		</div>
	);
};

const HighlightedReview: FC<HighlightedReviewProps> = ({
	isTestimonial,
	isLiteStorefront,
}) => {
	if (isTestimonial && !isLiteStorefront) {
		return (
			<div className={Styles.highlighted}>
				<StatusBadge size="sm" type="informational">
					Highlighted review
				</StatusBadge>
			</div>
		);
	}
	return null;
};

export const Review: FC<Props> = memo(
	({
		comments,
		content,
		createdDate,
		hidden = false,
		gallery,
		rating,
		reviewer: { firstName, lastName },
		showSecondaryCTA = false,
		vendorAnalytics,
		isTestimonial,
		border,
	}) => {
		const {
			commenterRole,
			createdDate: commenterCreatedDate,
			text: commenterText,
		} = comments[0] ?? {};
		const isMobile = useAppSelector(selectIsMobile);
		const [formattedCreatedDate, publishDate] = useReviewDate(createdDate);
		const abbrName = useAbbrName(firstName, lastName);
		const isLiteStorefront = useLiteStorefront();
		const shouldShowScannability = useStorefrontFeatureScannability();
		const starSize: Size = useMemo(
			() => (isMobile || shouldShowScannability ? 'sm' : 'md'),
			[isMobile, shouldShowScannability],
		);

		const enableSecondaryCTA = showSecondaryCTA && !isLiteStorefront;

		return (
			<HiddenWrapper hidden={hidden}>
				<div
					itemProp="review"
					itemScope
					itemType="http://schema.org/Review"
					data-testid="review-item"
				>
					<div
						itemScope
						itemProp="reviewRating"
						itemType="http://schema.org/Rating"
					>
						<meta itemProp="ratingValue" content={String(rating)} />
					</div>
					<meta itemProp="datePublished" content={publishDate} />
					<meta itemProp="description" content={content} />
					<div
						className={classNames(Styles.reviewWrapper, {
							[Styles.reviewWrapperBorder]: border,
						})}
					>
						{enableSecondaryCTA && shouldShowScannability && (
							<HighlightedRequest
								messageText="Any questions?"
								buttonText="Start a conversation"
								initiator="Reviews CTA"
								hideIfLead={true}
							/>
						)}
						{(!shouldShowScannability || isMobile) && (
							<HighlightedReview
								isTestimonial={isTestimonial}
								isLiteStorefront={isLiteStorefront}
							/>
						)}
						{shouldShowScannability ? (
							<HeaderScannability
								abbrName={abbrName}
								formattedCreatedDate={formattedCreatedDate}
								rating={rating}
								starSize={starSize}
								isMobile={isMobile}
								highlightedReview={
									<HighlightedReview
										isTestimonial={isTestimonial}
										isLiteStorefront={isLiteStorefront}
									/>
								}
								enableSecondaryCTA={enableSecondaryCTA}
							/>
						) : (
							<Header
								abbrName={abbrName}
								formattedCreatedDate={formattedCreatedDate}
								rating={rating}
								starSize={starSize}
							/>
						)}
						{shouldShowScannability && gallery.length > 0 ? (
							<div data-testid="review-body" className={Styles.infoWrapper}>
								<div className={Styles.bodyWrapper}>
									<ReviewBody
										content={content}
										vendorAnalytics={vendorAnalytics}
									/>
								</div>
								<div
									className={classNames({
										[Styles.galleryWrapperOne]: gallery.length === 1,
										[Styles.galleryWrapperTwo]: gallery.length === 2,
										[Styles.galleryWrapper]: gallery.length >= 3,
									})}
								>
									<Filmstrip gallery={gallery} />
								</div>
							</div>
						) : (
							<>
								<div data-testid="review-body">
									<ReviewBody
										content={content}
										vendorAnalytics={vendorAnalytics}
									/>
								</div>
								<Filmstrip gallery={gallery} />
							</>
						)}

						{!isLiteStorefront && (
							<VendorReply
								commenterRole={commenterRole}
								createdDate={commenterCreatedDate}
								text={commenterText}
								minimize={shouldShowScannability}
							/>
						)}
					</div>
				</div>
				{enableSecondaryCTA && !shouldShowScannability && (
					<ReviewSecondaryCTA />
				)}
			</HiddenWrapper>
		);
	},
);

export default Review;
